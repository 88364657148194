<template>
  <div>
    <!-- lesson title-->
    <div class="row">
      <div class="col">
        <h3>{{lesson.title}}</h3>
      </div>
    </div>
    <!-- video-->
    <div class="row">
      <div class="col">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
              class="embed-responsive-item"
              :src="lesson.videoCloudflareUrl"
              style="border: none;"
              height="720"
              width="1280"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
              id="stream-player"
          ></iframe>
        </div>
      </div>
    </div>
    <!-- next / previous button -->
    <div class="row p-2 mb-2" style="background-color: #f4eeee" v-if="showPreviousNext">
      <div class="col-2">
        <b-button size="sm" :disabled="lesson.isFirst" variant="success" @click="onClickPrevious">Previous</b-button>
      </div>
      <div class="col-8">
      </div>
      <div class="col-2">
        <b-button size="sm" :disabled="lesson.isLast" variant="success" @click="onClickNext">Next</b-button>
      </div>
    </div>
    <div class="row mt-3" v-if="!showPreviousNext"></div>
    <!-- lesson metadata section -->
    <div class="row">
      <div class="col-12 col-md-6">
        <!-- What -->
        <h5 style="color: #1b5ae9"><i class="bi bi-check-all"></i> What are we going to do ?</h5>
        <ul>
          <li v-for="(w, idx) in lesson.what" :key="idx">{{w}}</li>
        </ul>
        <hr>
        <!-- Branches -->
        <h5 style="color: #1b5ae9"><i class="bi bi-code"></i> Branches</h5>
        <div v-for="(b, projName) in lesson.branches" :key="projName" >
          <div v-if="b.start !== ''">
            <span style="color: darkblue; ">Project</span> : <span>{{projName}}</span> <br>
            Repository: <a :href="tutorial.repositories[projName].webUrl">{{tutorial.repositories[projName].gitUrl}}</a> <br>
            <span style="color: green"><i class="bi bi-play"></i> Start: </span><span class="branch-name ml-3">git checkout {{b.start}}</span>   <b-button pill size="sm" variant="outline-primary" @click="copyText(b.start)">Copy branch name</b-button> <br>
            <span style="color: darkred"><i class="bi bi-stop"></i> End: </span><span class="branch-name ml-3">git checkout {{b.end}}</span>   <b-button pill size="sm" variant="outline-primary" @click="copyText(b.end)">Copy branch name</b-button> <br>
          </div>
        </div>
        <hr>
        <!-- Links -->
        <h5 style="color: #4a10ea"><i class="bi bi-link-45deg" ></i> Links:</h5>
        <ul>
          <li v-for="(l, idx) in lesson.links" :key="idx"><a :href="l.href">{{l.description}}</a></li>
        </ul>
      </div>
      <div class="col col-md-6">
        <h5 style="color: #4a10ea"><i class="bi bi-save"></i> What do you need to remember ?</h5>
        <ul>
          <li v-for="(r, idx) in lesson.remember" :key="idx">{{r}}</li>
        </ul>
        <hr>
        <!-- Commands -->
        <h5 style="color: #f01060"><i class="bi bi-terminal" ></i> Commands to remember:</h5>
        <div v-for="(c, idx) in lesson.commands" :key="idx" style="border: #3a3a3e">
          <span style="color: darkblue">Type:</span> {{c.type}} <br>
          <span style="color: darkblue">Description:</span> {{c.description}} <br>
          <pre style="color: #171720" v-highlightjs v-text="c.command"></pre>
        </div>
        <hr>
      </div>
    </div>
    <!-- end lesson metadata section -->
  </div>
</template>

<script>
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import Consts from "@/consts";

export default {
  name: "OneLesson",
  props: {
    lesson: Object,
    tutorial: Object,
    showPreviousNext: Boolean
  },
  methods: {
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    copyText(text){
      navigator.clipboard.writeText(text);
      this.makeToast("Success", "Branch name copied in the clipboard", "success")
    },
    onClickNext(){
      this.loadingMainVideo = true
      AuthHelper.getCurrentUser(false).then((user) => {
        Webservice.getNextSession(user.idToken,this.tutorial.id, this.lesson.id).then((res)=> {
          this.lesson = res.data
          this.loadingMainVideo = false
        }).catch((err)=>{
          console.error(err)
          this.loadingMainVideo = false
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        // not logged in
        console.error(err)
        this.$router.push({name: Consts.ROUTE_NAMES.LOGIN})
      })
    },
    onClickPrevious(){
      this.loadingMainVideo = true
      AuthHelper.getCurrentUser(false).then((user) => {
        Webservice.getPreviousSession(user.idToken,this.tutorial.id, this.lesson.id).then((res)=> {
          this.lesson = res.data
          this.loadingMainVideo = false
        }).catch((err)=>{
          console.error(err)
          this.loadingMainVideo = false
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        // not logged in
        console.error(err)
        this.$router.push({name: Consts.ROUTE_NAMES.LOGIN})
      })
    },
  }
}
</script>

<style scoped>
.branch-name {
  background-color: #171720;
  color: #FFFFFF;
  display:inline;
  white-space:pre-wrap;
  line-height : 24px;
  font-size: 18px;
  font-family:arial, sans-serif;
  padding: 3px;
}
</style>