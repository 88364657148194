<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <Loader v-if="loading"></Loader>
      <div v-else>
        <div class="container mt-3">
          <h1>Course: {{course.tutorial.name}}</h1>
          <p v-html="course.tutorial.shortDescription"></p>
          <hr>
          <!-- Video to display -->
          <Loader v-if="loadingMainVideo"></Loader>
          <div v-else>
            <OneLesson :tutorial="course.tutorial" :lesson="course.currentLesson" :show-previous-next="true"></OneLesson>
          </div>

          <!-- Table of contents -->
          <TableOfContentTutorial :lessons="course.lessons"
                                  v-on:click-lesson="onClickLesson"
                                  v-on:complete-lesson="onCompleteLesson"
                                  :show-checkbox="true"
                                  :is-promotional="false"
          ></TableOfContentTutorial>
        </div>
      </div>
    </b-container>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
import Webservice from "@/webservice";
import AuthHelper from "@/authHelper";
import Consts from "@/consts";
import Loader from "@/components/Loader";
import OneLesson from "@/components/OneLesson";
import TableOfContentTutorial from "@/components/TableOfContentTutorial";
export default {
  name: "CourseDetail",
  components: {TableOfContentTutorial, OneLesson, Loader, NavBar},
  props: {
    // id of tutorial
    id: String,
    lessonId: String
  },
  data(){
    return {
      currentlyPlayedVideo:"",
      course: null,
      loading: false,
      loadingMainVideo: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      AuthHelper.getCurrentUser(false).then((user) => {
        Webservice.getMyCourseById(user.idToken,this.id, this.lessonId).then((res)=> {
          this.course = res.data
          this.loading = false
        }).catch((err)=>{
          console.error(err)
          this.loading = false
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        // not logged in
        console.error(err)
        this.$router.push({name: Consts.ROUTE_NAMES.LOGIN})
      })
    },
    onClickLesson(sessionClicked){
      window.scrollTo(0,0);
      this.course.currentLesson = sessionClicked
    },
    onCompleteLesson(lesson){
      AuthHelper.getCurrentUser(false).then((user) => {
        let req = {
          tutorialId:this.id,
        }
        if (lesson.completed) {
           req.doneLessonId = lesson.id
        } else {
          req.unDoneLessonId = lesson.id
        }
        Webservice.updateMyProgression(user.idToken,req).then((res)=> {
          this.loadingMainVideo = true
          this.course = res.data
          this.loadingMainVideo = false
        }).catch((err)=>{
          console.error(err)
          this.loading = false
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        // not logged in
        console.error(err)
        this.$router.push({name: Consts.ROUTE_NAMES.LOGIN})
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

  }
}
</script>

<style scoped>



</style>